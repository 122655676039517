<template>
	<div>
		<search-header></search-header>

		<div class="container">
			<div class="row">
				<div class="w-100 mt-3">
					<b-breadcrumb :items="map"></b-breadcrumb>
				</div>
				<div v-if="dict" class="col-12 py-2 border screen">
					<div v-if="area" class="row mb-2 mt-2 align-content-center">
						<div class="col-1 text-secondary mt-1">地区:</div>
						<div class="col">
							<div class="row">
								<a href="javascript:;" class="mr-3 px-2 py-1" :class="{'active' : chooseArea.province == p.code}" @click="onScreenClick('province',p.code)" v-for="p in area.province" :key="p.code">{{p.name}}</a>
							</div>
							<div v-if="getCity" class="row mt-2" >
								<a href="javascript:;" class="mr-3 px-2 py-1" :class="{'active' : chooseArea.city == c.code}"  @click="onScreenClick('city',c.code)" v-for="c in getCity" :key="c.code">{{c.name}}</a>
							</div>
							<div v-if="getArea" class="row mt-2" >
								<a href="javascript:;" class="mr-3 px-2 py-1" :class="{'active' : chooseArea.area == a.code}"  @click="onScreenClick('area',a.code)" v-for="a in getArea" :key="a.code">{{a.name}}</a>
							</div>
						</div>
					</div>
					
					<hr class="my-3" />
					
					<div class="row mb-2 align-content-center">
						<div class="col-1 text-secondary mt-1">用途:</div>
						<div class="col">
							<div class="row">
								<a href="javascript:;" class="mr-3 px-2 py-1" @click="onScreenClick('landUseTypeP','')" :class="{'active': useLandClassId == ''}">全部</a>
								<a href="javascript:;" @click="onScreenClick('landUseTypeP',u.id)" v-for="u in dict.landUseType" :key="u.id" class="mr-3 px-2 py-1" :class="{'active' : u.id == useLandClassId}">{{u.text}}</a>
							</div>
							<div v-if="useLandClassId" class="row mt-2">
								<a href="javascript:;" class="mr-3 px-2 py-1" @click="onScreenClick('landUseTypeC',useLandClassId)" :class="{'active': para.param.classId == useLandClassId}">全部</a>
								<a href="javascript:;" @click="onScreenClick('landUseTypeC',child.id)" :class="{ 'active' : para.param.classId == child.id }" v-for="child in getChildUseLandList" :key="child.id" class="mr-3 px-2 py-1">{{child.text}}</a>
							</div>
						</div>
					</div>

					<hr class="my-3" />

					<div class="row ">
						<div class="col-1 text-secondary mt-1">需求:</div>
						<div class="col">
							<div class="row">
								<a href="javascript:;" @click="onScreenClick('needsType','')" class="mr-3 px-2 py-1" :class="{'active' : para.param.needsType == ''}">全部</a>
								<a href="javascript:;" @click="onScreenClick('needsType',n.id)" v-for="n in dict.needsType" :key="n.id" class="mr-3 px-2 py-1" :class="{'active' : para.param.needsType == n.id}">{{n.text}}</a>
							</div>
						</div>
					</div>
					<hr class="my-3" />
					<div class="row">
						<div class="col-1 text-secondary mt-1">面积:</div>
						<div class="col">
							<div v-if="metreArray.includes(useLandClassValue)" class="row">
								<a href="javascript:;" @click="onScreenClick('areaRangeP',a.id)"  v-for="a in dict.areaRangeP" :key="a.id" class="mr-3 px-2 py-1"  :class="{'active' : areaRangeP == a.id}">{{a.text}}</a>
							</div>
							<div v-else class="row">
								<a href="javascript:;" @click="onScreenClick('areaRangeM',a.id)"  v-for="a in dict.areaRangeM" :key="a.id" class="mr-3 px-2 py-1"  :class="{'active' : areaRangeM == a.id}">{{a.text}}</a>
							</div>
						</div>
					</div>
					<hr class="my-3" />
					<div class="row ">
						<div class="col-1 text-secondary mt-1">年限:</div>
						<div class="col">
							<div class="row">
								<a href="javascript:;" @click="onScreenClick('years',y.id)" v-for="y in dict.years" :key="y.id" class="mr-3 px-2 py-1" :class="{'active' : years == y.id}">{{y.text}}</a>
							</div>
						</div>
					</div>
				</div>
			
				<div class="col-12 py-2 mt-3 border rounded">
					<div class="row pt-2 pb-2">
						<div class="col-1 text-secondary mt-1">排序:</div>
						<div class="col">
							<div class="row">
								<a href="javascript:;" @click="onSortClick(i)" v-for="s,i in sort" :key="i"  class="mr-4 px-2 py-1 rounded" :class="{'text-theme' : s.active}">{{s.text}} <b-icon :icon="s.orderBy == 'DESC' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon></a>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		
		<div class="container p-0">
			<div class="row my-4">
				<div v-if="list.records && list.records.length > 0" class="col-12">
					<div class="row">
						<div class="col-4" v-for="m,i in list.records" :key="i">
							<div class="border mb-3 p-3 rounded">
								<router-link :to="'/needs/detail?id=' + m.id" target="_blank"><h6>{{m.title}}</h6></router-link>
								<div class="mt-3">
									<div v-if="m.minPrice > 0 && m.maxPrice > 0">
										<span v-if="m.minPrice > 0 && m.maxPrice > 0" class="text-theme">{{m.minPrice }} ~ {{m.maxPrice}}</span>
										<span class="ml-2 text-secondary">{{getPriceUnitValue(m.priceUnit)}}</span>
									</div>
									<div v-else>
										<span class="text-theme">价格面议</span>
									</div>
								</div>
								<div class="mt-2 f-14 text-secondary clearfix">
									<div v-if="m.year" class="float-left">
										<b-icon icon="clock"></b-icon><span class="ml-1">{{m.year}} 年</span>
									</div>
									<div class="float-right">
										<b-icon icon="geo-alt"></b-icon><span class="ml-1">{{getAreaValue(m.area)}}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="clearfix text-center">
								<div v-if="list && list.total > list.size" class="my-4 d-flex justify-content-center">
									<b-pagination @change="onPageChange" :total-rows="list.total" :per-page="list.size" v-model="para.current"></b-pagination>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12" v-else>
					<div class="border mb-3 p-3 rounded height-400">
						<div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
							<div class="text-gray-777">没有找到需求信息，您还可以免费</div>
							<div class="ml-3">
								<router-link to="/user/needs/add" target="_blank">
									<b-button variant="success">登记需求</b-button>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<map-footer></map-footer>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	export default {
		data() {
			return {
				map: [{
						text: '农村综合产权服务平台',
						href: '/'
					},
					{
						text: '土地需求',
						active: true
					}
				],
				metreArray: ['商业及住宅','公共用地', '农房'],
				list: [],
				sort: [{
					text: '最新',
					filed: 'createdAt',
					orderBy: 'DESC',
					active: true,
				},{
					text: '面积',
					filed: 'measureArea',
					orderBy: 'DESC',
					active: false,
				},{
					text: '年限',
					filed: 'year',
					orderBy: 'DESC',
					active: false,
				}],
				useLandClassId: '',
				areaRangeP: '',
				areaRangeM: '',
				years: '',
				chooseArea: {
					province: '',
					city: '',
					area: '',
				},
				para: {
					param:{
						area: "",
						classId: "",
						needsType: "",
						yearMin: '', 
						yearMax: '', 
						measureAreaMin: '', 
          				measureAreaMax: '',
					},
					sortBy:{},
					size: 15,
					current: 1
				}
			}
		},
		computed: {
			...mapGetters(["area","dict"]),
			getCity() {
				if (this.area && this.chooseArea.province) {
					let c = this.area.city.filter(x => (x.level == 2 && x.parentCode == this.chooseArea.province) || x.code == '');
					if (c.length == 2) {
						this.chooseArea.city = c[1].code;
					} else {
						return c;
					}
				}
				return [];
			},
			getArea() {
				if (this.area && this.chooseArea.city) {
					let a = this.area.area.filter(x => (x.level == 3 && x.parentCode == this.chooseArea.city) || x.code == '');
					if (a) {
						return a;
					}
				}
				return [];
			},
			getAreaValue()	{
				return function(code) {
					let value = '';
					if(this.area) {
						let a = this.area.area.find(x => x.code == code)
						if(a) {
							value = a.name;
							let c = this.area.city.find(x => x.code == a.parentCode);
							if(c) {
								value = value + ' ' + c.name;
								let p = this.area.province.find(x => x.code == c.parentCode);
								if(p) {
									value = value + ' ' + p.name;
								}
							}
						}
					}
					return value.replace(' 市辖区','')
				}
			},
			getChildUseLandList() {
				let c = this.dict.landUseType.find(x=> x.id == this.useLandClassId);
				if (c)  {
					return c.children;
				} 
				return []
			},
			useLandClassValue() {
				let c = this.dict.landUseType.find(x=> x.id == this.useLandClassId);
				if (c)  {
					return c.text;
				} 
				return ''
			},
			getPriceUnitValue () {
				return function(id) {
					if(this.dict && this.dict.priceUnit) {
						let m = this.dict.priceUnit.find(x => x.id == id)
						if(m) {
							return m.text;
						}
					}
					return ''
				}
			},
		},
		methods: {
			onPageChange(i) {
				this.para.current = i;
				this.getList();
			},
			onSortClick(i) {
				let o = this.sort.findIndex(x=> x.active == true);
				this.sort = this.sort.map(x=> { return {
					text: x.text,
					filed: x.filed,
					orderBy: x.orderBy,
					active: false
				}});
				if(o == i) {
					this.sort[i].active = true;
					this.sort[i].orderBy = this.sort[i].orderBy == 'DESC' ? 'ASC' : 'DESC';
				} else {
					this.sort[i].active = true;
				}
				this.para.sortBy = {};
				this.para.sortBy[this.sort[i].filed] = this.sort[i].orderBy;
				this.getList();
			},
			onScreenClick(type, id) {
				this.para.current = 1;
				switch(type) {
					case 'province': 
						this.chooseArea.province = id
						this.chooseArea.city = '';
						this.chooseArea.area = '';
						this.para.param.area = id;
						break;
					case 'city': 
						this.para.param.area = id == '' ? this.chooseArea.province : id;
						this.chooseArea.city = id;
						this.chooseArea.area = '';
						break;
					case 'area': 
						this.para.param.area = id == '' ? this.chooseArea.city: id;
						this.chooseArea.area = id;
						break;
					case 'landUseTypeP':
						this.para.param.classId= id;
						this.useLandClassId = id
						if(this.metreArray.includes(this.useLandClassValue)) {
							let p = this.dict.areaRangeP.find(x=>x.id == this.areaRangeP);
							if(p) {
								this.para.param.measureAreaMin = p.measureAreaMin;
								this.para.param.measureAreaMax = p.measureAreaMax;
							}
						} else {
							let m = this.dict.areaRangeM.find(x=>x.id == this.areaRangeM);
							
							if(m) {
								this.para.param.measureAreaMin = m.measureAreaMin;
								this.para.param.measureAreaMax = m.measureAreaMax;
							}
						}
						break;
					case 'landUseTypeC':
						this.para.param.classId = id;
						break;
					case 'needsType':
						this.para.param.needsType = id;
						break;
					case 'areaRangeP':
						this.areaRangeP = id;
					 	let p = this.dict.areaRangeP.find(x=>x.id == id);
						if(p) {
							this.para.param.measureAreaMin = p.measureAreaMin;
							this.para.param.measureAreaMax = p.measureAreaMax;
						}
						break;
					case 'areaRangeM':
						this.areaRangeM = id;
						let m = this.dict.areaRangeM.find(x=>x.id == id);
						if(m) {
							this.para.param.measureAreaMin = m.measureAreaMin;
							this.para.param.measureAreaMax = m.measureAreaMax;
						}
						break;
					case 'years':
						this.years = id;
						let y = this.dict.years.find(x=>x.id == id);
						if(y) {
							this.para.param.yearMin = y.yearMin;
							this.para.param.yearMax = y.yearMax;
						}
						break;
				}
				this.getList();
			},
			getList() {
				this.$http.post(this.$api.supply.getNeedsList, this.para).then(res => {
					if(res.code == 1) {
						this.list = res.result;
					}
				})
			}
		},
		created() {
			this.para.sortBy[this.sort[0].filed] = this.sort[0].orderBy;
			if(this.$route.query.province) {
				this.chooseArea.province = this.$route.query.province;
				this.para.param.area = this.chooseArea.province;
			}

			if(this.$route.query.city) {
				this.chooseArea.city = this.$route.query.city;
				this.para.param.area = this.chooseArea.city;
			}

			if(this.$route.query.area) {
				this.chooseArea.area = this.$route.query.area;
				this.para.param.area = this.chooseArea.area;
			}
			
			if(this.$route.query.classid) {
				this.useLandClassId = this.$route.query.classid;
				this.para.param.classId = this.useLandClassId;
			}

      if(this.$route.query.needsType) {
        this.needsType = this.$route.query.needsType;
        this.para.param.needsType = this.needsType;
      }

			if(this.$route.query.childclassid) {
				this.para.param.classId = this.$route.query.childclassid;
			}

			if(this.$route.query.arp) {
				this.areaRangeP = this.$route.query.arp;
				let p = this.dict.areaRangeP.find(x=>x.id == this.areaRangeP);
				if(p) {
					this.para.param.measureAreaMin = p.measureAreaMin;
					this.para.param.measureAreaMax = p.measureAreaMax;
				}
			} else {
				if(this.$route.query.arm) {
					this.areaRangeM = this.$route.query.arm;
					let m = this.dict.areaRangeM.find(x=>x.id == this.areaRangeM);
					if(m) {
						this.para.param.measureAreaMin = m.measureAreaMin;
						this.para.param.measureAreaMax = m.measureAreaMax;
					}
				}
			}
			this.getList();
		}
	}
</script>

<style>
	.screen .active {
		background: #00cc00;
		color: #FFFFFF;
		border-radius: 6px;
	}
</style>
